.circularWrapper {
  position: fixed;
  background: white;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waitTextWrapper {
  color: #009FE3;
  font-weight: 900;
  font-size: 20px;
}
