.App {
  display: block;
  width: 100%;
  min-height: 500px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-logo {
  width: 250px;
  border-style: none;
  float: right;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.subTitle {
  color: #9D9D9D;
  text-align: left;
  margin-bottom: 0;
}

body {
  font-family: sans-serif;
  font-size: small;
  color: black;
  min-height: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
}

input[type=file] {
  margin-top: 2px;
  margin-bottom: 2px;
  min-width: 500px;
}

input[type=text] {
  margin-top: 2px;
  margin-bottom: 2px;
  min-width: 200px;
}

input[type=checkbox] {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 6px;
}

.disabledCheckbox[type=checkbox]:checked:before {
  content: "✓";
  background-color: grey;
  color: white;
  border-left: 3px solid grey;
  border-right: 3px solid grey;
  border-top: 2px solid grey;
  border-radius: 3px;
  font-size: 9px;
  position: relative;
  top: -1.8px;
  font-weight: bold;
}

.disabledCheckbox[type=checkbox]:indeterminate:before {
  content: "-";
  background-color: grey;
  color: white;
  border-left: 4px solid grey;
  border-right: 3px solid grey;
  border-bottom: 1px solid gray;
  border-radius: 3px;
  font-size: 10px;
  font-family: monospace;
  position: relative;
  top: -3px;
  font-weight: bold;
}

button {
  margin: 5px;
}

h1 {
  margin-top: 3px;
  margin-bottom: 2px;
  font-family: Verdana;
  font-weight: bold;
  font-size: x-large;
  text-align: center;
}

h2 {
  font-family: Verdana;
  font-weight: lighter;
  font-size: large;
  text-align: center;
}

h3 {
  font-family: Verdana;
  font-weight: normal;
  font-size: medium;
  text-align: center;
}

h4 {
  margin: 0 0 0 10px;
  font-size: 14px;
  color: red;
}

p {
  font-family: Verdana;
  margin-top: 0em;
  margin-bottom: 1em;
  font-size: small;
  color: black;
}

img {
  border-style: none;
  float: right;
}

a {
  text-decoration: none;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 50px;
  margin-left: 50px;
}

.contentContainer {
  display: flex;
  flex-grow: 1;
}

.form, .treeContainer {
  display: flex;
  flex-direction: column;
}

.form {
  align-items: flex-start;
  width: 265px;
  padding: 0;
}

.treeContainer {
  height: 82vh;
  width: 70%;
}

.treeTitle {
  margin-bottom: 5px!important;
}

.tree {
  margin-top: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.deprecatedItem {
  color: #9D9D9D;
}

.treeReference {
  align-self: center;
  font-size: 10px;
  margin: 0px;
}

.outputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.rct-collapse-btn {
  padding: 0;
  background-color: white;
}

.icon {
  width: 15px;
  height: 15px;
}

footer {
  padding-bottom: 10px;
}

footer p {
  margin: 0;
  color: #9D9D9D;
  font-weight: 100;
}

.field {
  border: none;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.bar {
  width: 200px;
  background-color: lightgrey;
  visibility: hidden;
}

.textField-focused fieldset {
  border-color: #009FE3 !important;
}

.textField-label-focused {
  color: #009FE3 !important;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkbox {
  color: #009FE3 !important;
  padding: 0 !important;
  margin-right: 10px !important;
}

.checkbox:hover {
  background-color: transparent !important;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.submitButton {
  margin: 0;
  display: inline-block;
  border: none;
  height: 46px;
  min-width: 210px;
  background-color: #64C97C;
  border-radius: 23px;
  padding: 15px 30px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}

.submitButton:hover {
  background-color: #4eb767;
}

.submitButton:disabled {
  background-color: #CCC;
  color: #666666;
  cursor: auto;
}

.downloadButton {
  background-color: #009FE3;
}

.downloadButton:hover {
  background-color: #006c9a;
}

.redirectButtonContainers, .fieldsButtonContainers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.redirectButtonContainers {
  width: 100%;
  margin: 40px 0 10px 0;
}

.redirectButton {
  background: none;
  margin: 0px 8px 0px 0px;
  padding-top: 8px;
  height: 36px;
  width: 130px;
  border: 2px solid #c7c7c7;
  border-radius: 23px;
  color: #009FE3;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}

.errorContainer {
  width: 75%;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  resize: both;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
  overflow: hidden;
}

.errorMessage {
  width: 100%;
  height: calc(100% - 10px);
  padding: 10px;
  border: none;
  resize: none;
  outline: none;
  overflow: hidden;
  color: red;
}

.fileReadyButton {
  padding: 10px 20px;
  border: 1px solid #009FE3;
  border-radius: 8px;
  color: #009FE3;
}

.fileReadyButton:hover {
  background-color:#009FE3;
  color: #FFF;
}

.clearFieldsButton {
  background: none;
  align-self: center;
  height: 36px;
  width: 150px;
  margin-bottom: 10px;
  margin-top: 75px;
  border: 2px solid #c7c7c7;
  border-radius: 23px;
  color: #009FE3;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.showResultsButton {
  width: 210px;
  margin: 0px;
  padding: 10px;
  color: #009FE3;
  background: none;
  border: none;
  border-color: white;
  font-size: 14px;
  text-align: center;
}

.submitButton.closeResultsButton {
  margin: 0;
  background-color: #bfbfbf;
}

ol {
  margin-top: 0;
  padding: 0;
  overflow: hidden;
}

ol li {
  padding-left: 45px;
}

.buttonsWrapper {
  display: flex;
}
